import { favoriteEpisodesSelector, FAVORITES, UNFAVORITE_EPISODE, userSelector } from 'ducks/user'
import { get } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getExclusiveContent } from 'services/exclusiveContent'
import { CardsFeatured } from 'views/components/CardsFeatured'
import { CardFeaturedItem } from 'views/components/CardsFeatured/Item'
import { Greeting } from 'views/components/Greeting'
import { PaginatedShowcase } from 'views/components/PaginatedShowcase'
import { ShowcaseItem } from 'views/components/Showcase/Item'
import { userBannerSelector } from 'ducks/staticContents'
import { useSpotify } from 'hooks/useSpotifyPlayer'
import { Container } from './styled'

export const ExclusiveHome: FC = () => {
  const { setEmbed } = useSpotify()
  const dispatch = useDispatch()
  const banner = useSelector(userBannerSelector)
  const userState = useSelector(userSelector)
  const name = userState.get('name')
  const favoriteEpisodes = useSelector(favoriteEpisodesSelector)
  const [showcaseItems, setShowcaseItems] = useState<ShowcaseItem[]>([])
  const favoriteEpisodesToCardsFeatured: CardFeaturedItem[] =
    favoriteEpisodes?.map((favorite) => ({
      title: favorite.episode.title,
      image: favorite.episode.image || '',
      subtitle: favorite.podcast.title,
      description: `${favorite.episode.duration.toString()} minutos`,
      link: `/podcasts/${favorite.podcast.id}/episode/${favorite.episode.id}`,
      favorite: true,
      id: favorite.episode.id.toString(),
      parent: favorite.podcast.id.toString(),
      keyId: favorite.id,
      linkEpisode: favorite.episode.link_episode,
    })) || []

  const handleFavorite = (item: CardFeaturedItem) => {
    const { id, parent, keyId } = item
    if (id && parent && keyId) {
      const payload = {
        podcast: parent,
        episode: id,
        favorite: keyId,
      }
      dispatch({ type: UNFAVORITE_EPISODE, payload })
    }
  }

  const handlePlayEpisode = (item: CardFeaturedItem) => {
    if (item.linkEpisode) {
      setEmbed(item.linkEpisode)
    }
  }

  useEffect(() => {
    getExclusiveContent().then(({ data }) => {
      const exclusiveContent: ShowcaseItem[] = data?.map(
        (content: { title: string; short_text: string; id: string }) => ({
          title: content.title,
          description: content.short_text,
          image: get(content, ['thumbnail_image', 0, 'virtualPath']),
          link: `/exclusive/${content.id}`,
        })
      )
      setShowcaseItems(exclusiveContent)
    })
  }, [])

  useEffect(() => {
    dispatch(FAVORITES.trigger())
  }, [dispatch])

  return (
    <Container>
      <Greeting
        greeting="Hola"
        message={banner.gratitude}
        name={name}
        link={{
          label: 'Gestionar suscripción',
          path: '/subscription-management',
        }}
        buttonLink={{
          label: 'Chat Discord',
          path: banner.discord,
          target: true,
        }}
      />
      <PaginatedShowcase items={showcaseItems} dangerHtml title="Contenido Exclusivo" />
      <CardsFeatured
        title="Favoritos"
        subtitle="Puedes seleccionar los episodios que más te gusten"
        items={favoriteEpisodesToCardsFeatured}
        midiaDisplay
        showFav
        favColor="#F80000"
        onFavItem={handleFavorite}
        onPlayItem={handlePlayEpisode}
      />
    </Container>
  )
}
