import React, { FC, useState } from 'react'
import { FiMenu } from 'react-icons/fi'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import { renderSocial } from '../utils'

import {
  AuthContainer,
  Bar,
  Container,
  Content,
  Logo,
  MenuLink,
  MenuOptions,
  RegisterButton,
  RegisterOutline,
  SocialsContainer,
  SocialLink,
  ToggleButton,
  LogoutButton,
} from './styled'

export type Link = {
  label: string
  path: string
  target?: boolean
}

type Props = {
  onClickLogo?: () => void
  backgroundColor?: string // Background Color for the top bar
  buttonBackgroundColor?: string // Background Color for the button bar
  buttonTextColor?: string // Text Color for the button
  textColor?: string // Color of login and options text
  login?: Link // Login Label to display and link to login page
  logo?: string // Logo URL Image
  options?: Link[] // Navigation options that will appear on the bottom section
  register?: Link // Register Label to display inside the button and link to login page
  resizeLogo?: boolean // Option to resize the logo when scrolled
  socials?: {
    // Supported social links
    facebook?: string
    instagram?: string
    twitter?: string
  }
  handleLogout: () => void
  isAuthenticated?: boolean
  logout?: string
  profile?: Link
}

export const Mobile: FC<Props> = (props) => {
  const {
    backgroundColor,
    buttonBackgroundColor,
    buttonTextColor,
    login = {
      label: 'Ingresa',
      path: '/login',
    },
    logo,
    options = [],
    register = {
      label: 'Hazte Cómplice',
      path: '/sign-up',
    },
    socials = {},
    textColor,
    onClickLogo,
    handleLogout,
    isAuthenticated,
    logout,
    profile,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState)
  }

  const goToHome = () => {
    if (onClickLogo) onClickLogo()
  }

  const onLogout = () => {
    handleLogout()
    toggleMenu()
  }

  return (
    <Container>
      <Bar backgroundColor={backgroundColor} color={textColor} isOpen={isOpen}>
        <ToggleButton onClick={toggleMenu}>{isOpen ? <AiOutlineCloseSquare /> : <FiMenu />}</ToggleButton>
        {logo && <Logo onClick={goToHome} src={logo} />}
      </Bar>
      <Content backgroundColor={backgroundColor} color={textColor} isOpen={isOpen}>
        <AuthContainer>
          {isAuthenticated ? (
            <>
              <LogoutButton color={textColor} onClick={onLogout}>
                {logout || 'Cerrar sesión'}
              </LogoutButton>
              <RegisterButton
                backgroundColor={buttonBackgroundColor}
                textColor={buttonTextColor}
                to={profile?.path || '/exclusive'}
                target={profile?.target ? 'blank' : undefined}
                onClick={toggleMenu}
              >
                <RegisterOutline />
                {profile?.path || 'Mi Cuenta'}
              </RegisterButton>
            </>
          ) : (
            <>
              {login && (
                <MenuLink
                  color={textColor}
                  to={login.path}
                  target={login?.target ? 'blank' : undefined}
                  onClick={toggleMenu}
                >
                  {login.label}
                </MenuLink>
              )}
              {register && (
                <RegisterButton
                  backgroundColor={buttonBackgroundColor}
                  textColor={buttonTextColor}
                  to={register.path}
                  target={register?.target ? 'blank' : undefined}
                  onClick={toggleMenu}
                >
                  <RegisterOutline />
                  {register.label}
                </RegisterButton>
              )}
            </>
          )}
        </AuthContainer>
        <MenuOptions>
          {options?.map((option) => (
            <MenuLink
              color={textColor}
              key={option.label + option.path}
              to={option.path || ''}
              target={option?.target ? 'blank' : undefined}
              onClick={toggleMenu}
            >
              {option.label}
            </MenuLink>
          ))}
        </MenuOptions>
        <SocialsContainer>
          {Object.entries(socials).map(([key, value]) => (
            <SocialLink key={key} href={value} target="blank">
              {renderSocial(key)}
            </SocialLink>
          ))}
        </SocialsContainer>
      </Content>
    </Container>
  )
}
