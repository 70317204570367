import { FC, useMemo } from 'react'
import UserPNG from 'assets/images/user.png'
import useScrollPosition from '../useScrollPosition'
import { renderSocial } from '../utils'
import {
  Container,
  BottomContainer,
  Logo,
  MenuLink,
  MenuOptions,
  RegisterButton,
  RegisterOutline,
  Row,
  SocialsContainer,
  SocialLink,
  TopContainer,
  LogoutButton,
  ProfileImage,
  LinkContainer,
} from './styled'

export type Link = {
  label: string
  path: string
  target?: boolean
}

type Props = {
  onClickLogo?: () => void
  backgroundColor?: string // Background Color for the top bar
  buttonBackgroundColor?: string // Background Color for the button bar
  buttonTextColor?: string // Text Color for the button
  textColor?: string // Color of login and options text
  login?: Link // Login Label to display and link to login page
  logo?: string // Logo URL Image
  options?: Link[] // Navigation options that will appear on the bottom section
  register?: Link // Register Label to display inside the button and link to login page
  resizeLogo?: boolean // Option to resize the logo when scrolled
  socials?: {
    // Supported social links
    facebook?: string
    instagram?: string
    twitter?: string
  }
  handleLogout: () => void
  logout?: string
  isAuthenticated?: boolean
  profile?: Link
}

const START_ANIMATE_SCROLL_POSITION = 100

export const Desktop: FC<Props> = (props) => {
  const {
    backgroundColor,
    buttonBackgroundColor,
    buttonTextColor,
    login = {
      label: 'Ingresa',
      path: '/login',
    },
    logo,
    options = [],
    register = {
      label: 'Hazte Cómplice',
      path: '/sign-up',
    },
    resizeLogo,
    socials = {},
    textColor,
    onClickLogo,
    handleLogout,
    isAuthenticated,
    logout,
    profile,
  } = props
  const scrollPosition = useScrollPosition()
  const shouldAnimate = useMemo(() => scrollPosition > START_ANIMATE_SCROLL_POSITION, [scrollPosition])

  const goToHome = () => {
    if (onClickLogo) onClickLogo()
  }

  return (
    <Container>
      <TopContainer backgroundColor={backgroundColor}>
        <Row flexGrow={1}>
          {logo && <Logo onClick={goToHome} animate={shouldAnimate && resizeLogo} backgroundImage={`url(${logo})`} />}
          <MenuOptions animate={shouldAnimate}>
            {options?.map((option) => (
              <LinkContainer key={option.label + option.path}>
                <MenuLink color={textColor} to={option.path || ''} target={option?.target ? 'blank' : undefined}>
                  {option.label}
                </MenuLink>
              </LinkContainer>
            ))}
            <SocialsContainer animate={shouldAnimate}>
              {Object.entries(socials).map(([key, value]) => (
                <SocialLink key={key} href={value} target="blank" animate={shouldAnimate}>
                  {renderSocial(key)}
                </SocialLink>
              ))}
            </SocialsContainer>
          </MenuOptions>
        </Row>
        <Row>
          {isAuthenticated ? (
            <>
              <LogoutButton color={textColor} onClick={handleLogout}>
                {logout || 'Cerrar sesión'}
              </LogoutButton>
              <MenuLink to={profile?.path || '/exclusive'} target={profile?.target ? 'blank' : undefined}>
                <ProfileImage src={UserPNG} />
              </MenuLink>
            </>
          ) : (
            <>
              {login && (
                <MenuLink color={textColor} to={login.path} target={login?.target ? 'blank' : undefined}>
                  {login.label}
                </MenuLink>
              )}
              {register && (
                <RegisterButton
                  backgroundColor={buttonBackgroundColor}
                  textColor={buttonTextColor}
                  to={register.path}
                  target={register?.target ? 'blank' : undefined}
                >
                  <RegisterOutline />
                  {register.label}
                </RegisterButton>
              )}
            </>
          )}
        </Row>
      </TopContainer>
      <BottomContainer animate={shouldAnimate} />
    </Container>
  )
}
