/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState, useMemo } from 'react'
import { PlanType, Plan } from './Plan'
import { Button, Container, Footer, Grid, Title, Subtitle } from './styled'
import { SingleSelect } from '../Inputs/SingleSelect'

type Props = {
  title?: string
  hideFilter?: boolean
  subtitle?: string
  plans?: Array<Omit<PlanType, 'onClick'>>
  cardButton?: string
  cardButtonColor?: string
  cardButtonText?: string
  cardBackgroundColor?: string
  cardTextColor?: string
  featuredCardBackgroundColor?: string
  featuredButtonColor?: string
  featuredButtonTextColor?: string
  footerTitle?: string
  footerButton?: string
  footerButtonLink?: string
  footerButtonTarget?: boolean
  textColor?: string
  disableSelectAction?: boolean
  selectedPlan: number | string | null
  selectPlan: (plan: Omit<PlanType, 'onClick'>) => void
}

const filterOptions = [
  {
    label: 'Mensual',
    value: 'Monthly',
  },
  {
    label: 'Anual',
    value: 'Yearly',
  },
  {
    label: 'Un Solo Pago',
    value: 'Once',
  },
]

export const Plans: FC<Props> = (props) => {
  const {
    title,
    subtitle,
    cardButton,
    cardButtonColor,
    cardButtonText,
    cardTextColor,
    cardBackgroundColor,
    featuredCardBackgroundColor,
    featuredButtonColor,
    featuredButtonTextColor,
    footerTitle,
    footerButton,
    footerButtonLink,
    footerButtonTarget,
    plans,
    disableSelectAction,
    textColor,
    selectPlan,
    selectedPlan,
    hideFilter,
  } = props

  const [filter, setFilter] = useState(process.env.REACT_APP_DEFAULT_PLAN_PERIOD)

  const plansState = useMemo(() => {
    if (hideFilter || !filter || filter === 'all') return plans || []

    if (filter === 'Once') {
      return plans?.filter((plan: any) => plan.oneTime) || []
    }

    return plans?.filter((plan: any) => plan.periodValue === filter && !plan.oneTime) || []
  }, [filter, plans, hideFilter])

  const handleFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value)
  }

  const renderPlan = (plan: Omit<PlanType, 'onClick'>) => {
    const onClick = () => {
      selectPlan(plan)
    }

    return (
      <Plan
        key={plansState.indexOf(plan)}
        {...plan}
        textColor={cardTextColor}
        backgroundColor={cardBackgroundColor}
        featuredBackgroundColor={featuredCardBackgroundColor}
        buttonColor={cardButtonColor}
        buttonTextColor={cardButtonText}
        buttonFeaturedColor={featuredButtonColor}
        buttonFeaturedTextColor={featuredButtonTextColor}
        button={cardButton}
        disableSelectAction={disableSelectAction}
        onClick={onClick}
        featured={selectedPlan === plan.id}
      />
    )
  }

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      {!hideFilter && (
        <>
          <Subtitle>{subtitle}</Subtitle>
          <div>
            <SingleSelect value={filter} onChange={handleFilter} options={filterOptions} />
          </div>
        </>
      )}
      <Grid>{plansState?.map(renderPlan)}</Grid>

      <Footer>
        <Subtitle>{footerTitle}</Subtitle>
        <Button target={footerButtonTarget ? 'blank' : undefined} to={footerButtonLink || ''}>
          {footerButton}
        </Button>
      </Footer>
    </Container>
  )
}
