import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Background = styled.div<BackgroundProps>`
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  mix-blend-mode: color-dodge;
  height: 100%;
  width: 100%;
  position: absolute;
  filter: grayscale(0.5);
  opacity: 0.4;
  top: 0;
  left: 0;
  z-index: 1;
  ${background};
  @media screen and (max-width: 450px) {
    background: transparent;
  }
`

export const Container = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  padding: 75px 10%;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    padding: 75px 5%;
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 450px) {
    padding-bottom: 0;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    height: auto;
    display: block;
    width: 100%;
  }
`

export const Separator = styled.div`
  width: 0;
  width: 0px;
  height: 100%;
  border: 1px solid #c8c8c8;
  position: relative;
  z-index: 1;
  @media screen and (min-width: 1920px) {
    height: 650px;
  }
  @media screen and (max-width: 768px) {
    height: 0;
    width: 100%;
    margin: 20px 0;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f7f7f7;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 450px) {
    background: transparent;
  }
`
