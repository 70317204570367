import { FC, useEffect, useState } from 'react'
import { Highlight } from 'views/components/Highlight'
import { CardsFeatured } from 'views/components/CardsFeatured'
import { Showcase } from 'views/components/Showcase'
import { useDispatch, useSelector } from 'react-redux'
import { generalSubscriptionSelector } from 'ducks/staticContents'
import get from 'lodash/get'
import { getPodcastAndFeaturedEpisodes } from 'services/podcast/getPodcastAndFeaturedEpisodes'
import { FavoriteEpisode, FAVORITE_EPISODE, rolsSelector, UNFAVORITE_EPISODE, userSelector } from 'ducks/user'
import useFavorite from 'hooks/useFavorite'
import { CardFeaturedItem } from 'views/components/CardsFeatured/Item'
import moment from 'moment'
import { useSpotify } from 'hooks/useSpotifyPlayer'
import intersection from 'lodash/intersection'
import { Container } from './styled'

type PodcastToSort = {
  created_at: string
}

const sortPodcastsByDate = (podcast1: PodcastToSort, podcast2: PodcastToSort) => {
  const date1 = moment(podcast1.created_at)
  const date2 = moment(podcast2.created_at)
  if (date1 < date2) {
    return -1
  }
  if (date1 > date2) {
    return 1
  }
  return 0
}

export const Podcasts: FC = () => {
  const generalText = useSelector(generalSubscriptionSelector)
  const { setEmbed } = useSpotify()
  const rols = useSelector(rolsSelector)
  const [podcasts, setPodcasts] = useState([])
  const [featuredEpisodes, setFeatured] = useState<any>([])
  const userState = useSelector(userSelector)
  const isAuthenticated = !!userState.get('token')
  const dispatch = useDispatch()
  const { isFavorite, getFavoriteId } = useFavorite()

  useEffect(() => {
    getPodcastAndFeaturedEpisodes().then(({ data }) => {
      if (Array.isArray(data?.lnfPodcasts)) {
        setPodcasts(
          data.lnfPodcasts
            .map((item: any) => {
              const dataEpisode: any = data?.lnfEpisodes.filter(
                (episode: any) => episode.id === `${item.feature_episode_id}`
              )
              const slugepisode: any = get(dataEpisode, [0, 'slug'])
              return {
                ...item,
                image: get(item, ['attachement', 0, 'virtualPath']),
                link: `/podcasts/${item.slug}/episode/${slugepisode}`,
              }
            })
            .sort(sortPodcastsByDate)
        )
      }
      if (Array.isArray(data?.lnfEpisodes)) {
        setFeatured(
          data.lnfEpisodes
            .filter((f: any) => f.featured)
            .filter((e: any) => {
              if (
                process.env.REACT_APP_PUBLIC_ROL_ID &&
                e.rols?.find((r: any) => r?.rol?.id === process.env.REACT_APP_PUBLIC_ROL_ID)
              ) {
                return true
              }

              if (!e.rols?.length) {
                return true
              }

              return (
                e?.lnf_podcast_id?.title &&
                !!intersection(
                  e.rols?.map((r: any) => r?.rol?.id),
                  rols
                )?.length
              )
            })
            .map((item: any) => ({
              title: item.title,
              image: get(item, ['attachement_featured', 0, 'virtualPath']),
              subtitle: item.lnf_podcast_id.title,
              description: item.duration,
              link: `podcasts/${item.lnf_podcast_id.slug}/episode/${item.slug}`,
              parent: item.lnf_podcast_id.id,
              id: item.id,
              favorite: isFavorite(item.id, item.lnf_podcast_id.id),
              keyId: getFavoriteId(item.id, item.lnf_podcast_id.id),
              linkEpisode: item.link_episode,
              order: item.order,
            }))
            .sort((a: any, b: any) => a.order - b.order)
        )
      }
    })
  }, [setPodcasts, setFeatured, isFavorite, getFavoriteId, rols])

  const handleFavorite = (item: CardFeaturedItem) => {
    const { parent, id, keyId } = item
    if (item.favorite) {
      const payload = {
        podcast: parent,
        episode: id,
        favorite: keyId,
      }
      dispatch({ type: UNFAVORITE_EPISODE, payload })
    } else {
      const data: FavoriteEpisode = {
        id: '',
        episode: {
          // eslint-disable-next-line radix
          id: parseInt(id || ''),
          title: item.title,
          duration: parseFloat(item.description || ''),
          image: item.image,
        },
        podcast: {
          // eslint-disable-next-line radix
          id: parseInt(parent || ''),
          title: item?.subtitle || '',
        },
      }
      const payload = {
        podcast: parent,
        episode: id,
        data,
      }
      dispatch({ type: FAVORITE_EPISODE, payload })
    }
  }

  const handlePlayEpisode = (item: CardFeaturedItem) => {
    if (item.linkEpisode) {
      setEmbed(item.linkEpisode)
    }
  }

  return (
    <Container>
      <Showcase
        backgroundImage="https://i.imgur.com/MDEPm2T.png"
        items={podcasts}
        dangerHtml
        headerTextColor="white"
        title="Podcasts"
        subtitle="Conoce todos nuestros shows"
      />
      <CardsFeatured
        title="Episodios Destacados"
        items={featuredEpisodes}
        midiaDisplay
        showFav={isAuthenticated}
        onFavItem={handleFavorite}
        onPlayItem={handlePlayEpisode}
      />
      <Highlight
        button={generalText?.get('subscribeButtonText')}
        message={generalText?.get('subscribeText')}
        link={generalText?.get('newsletter_url')}
        buttonColor="black"
        buttonTextColor="white"
        backgroundImage="https://i.imgur.com/QXXBnKY.png"
        noBottomSpacing
      />
    </Container>
  )
}
