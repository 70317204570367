import { ChangeEvent, FC, useEffect } from 'react'
import { SignupErrors, SignupValues } from 'views/screens/Signup'
import { Container, Grid, TextInputContainer, TextInput, TextInputError, Title, Subtitle } from './styled'

type Props = {
  onMount?: () => void
  title?: string
  subtitle?: string
  textColor?: string
  name?: string
  lastName?: string
  email?: string
  password?: string
  confirmPassword?: string
  values: SignupValues
  errors: SignupErrors
  handleChange: (e: ChangeEvent<any>) => void
}

export const SignupForm: FC<Props> = (props) => {
  const {
    values,
    handleChange,
    errors,
    title,
    subtitle,
    textColor,
    name,
    lastName,
    email,
    password,
    confirmPassword,
    onMount,
  } = props

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [onMount])

  return (
    <Container color={textColor}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Grid>
        <TextInputContainer>
          <TextInput onChange={handleChange} value={values.name} name="name" placeholder={name || 'Nombre*'} />
          {errors.name && <TextInputError>{errors.name}</TextInputError>}
        </TextInputContainer>
        <TextInputContainer>
          <TextInput
            onChange={handleChange}
            value={values.lastName}
            name="lastName"
            placeholder={lastName || 'Apellidos*'}
          />
          {errors.lastName && <TextInputError>{errors.lastName}</TextInputError>}
        </TextInputContainer>
        <TextInputContainer>
          <TextInput
            onChange={handleChange}
            value={values.email}
            name="email"
            placeholder={email || 'Correo electrónico*'}
          />
          {errors.email && <TextInputError>{errors.email}</TextInputError>}
        </TextInputContainer>
        <TextInputContainer>
          <TextInput
            onChange={handleChange}
            value={values.password}
            name="password"
            placeholder={password || 'Contraseña*'}
            type="password"
          />
          {errors.password && <TextInputError>{errors.password}</TextInputError>}
        </TextInputContainer>
        <TextInputContainer>
          <TextInput
            onChange={handleChange}
            value={values.confirmPassword}
            name="confirmPassword"
            placeholder={confirmPassword || 'Repetir Contraseña*'}
            type="password"
          />
          {errors.confirmPassword && <TextInputError>{errors.confirmPassword}</TextInputError>}
        </TextInputContainer>
      </Grid>
    </Container>
  )
}
