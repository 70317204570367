import { ServiceResponse } from '@types'
import axios from 'axios'

export const getActive = async (email: string): Promise<ServiceResponse> => {
  const query = `
    query {
      subscPlans(
        where: { subsc_client_id: { email: "${email}"}, status:"Activo"  }
      ) {
        id
        status
        plan: subsc_subscription_plan_id {
          id
          tfm_id
          status
          slug
          name
          value
          code
          period
          period_value
          paymentTypes: subsc_subscription_plan_subsc_payment_types_id {
            id
            type: subsc_payment_type_id {
              id
              name
              slug
              tfm_id
              attachment
            }
          }
          roles: subsc_subscription_plan_subsc_rols_id {
            id
            tfm_id
            role: subsc_role_id {
              id
              tfm_id
              slug
              name
              description
            }
          }
          currency: subsc_currency_id {
            id
            tfm_id
            name
            slug
          }
          features: subsc_plan_features_id {
            id
            tfm_id
            feature: subsc_feature_id {
              description
              order: position_order
              tfm_id
              id
            }
          }
          categories: subsc_plan_categories {
            id
            tfm_id
            subsc_category_id {
              id
              tfm_id
              name
              order: position_order
            }
          }
        }
      }
    }
  `
  try {
    const {
      data: {
        data: { subscPlans },
      },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, { query })

    return { ok: true, data: subscPlans }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
