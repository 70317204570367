import React, { FC, useEffect, useState } from 'react'
import { Banner } from 'views/components/Banner'
import { CardCollection } from 'views/components/CardCollection'
import { Features } from 'views/components/Features'
import { Gallery } from 'views/components/Gallery'
import { Highlight } from 'views/components/Highlight'
import { Newsletter } from 'views/components/Newsletter'
import { Promote } from 'views/components/Promote'
import { useSelector } from 'react-redux'
import { homeComponentsSelector } from 'ducks/staticContents'
import { componentsSlugs } from 'lib/constants/componentsSlugs'
import { getPodcast } from 'services/podcast/get'
import get from 'lodash/get'
import { Container } from './styled'

type DataToOrder = {
  order: null | number
}

const sortByOrderFromTFM = (a: DataToOrder, b: DataToOrder) => {
  if (a.order && !b.order) {
    return -1
  }
  if (!a.order && b.order) {
    return 1
  }
  if (a.order && b.order) {
    if (a.order < b.order) {
      return -1
    }
    if (a.order > b.order) {
      return 1
    }
  }
  return 0
}

const Home: FC = () => {
  const [data, setData] = useState<any>([])

  const {
    [componentsSlugs.MAIN_SLIDER]: banner,
    [componentsSlugs.NEWSLETTER]: newsletter,
    [componentsSlugs.ABOUT]: about,
    [componentsSlugs.SUBSCRIPTION]: subscription,
    [componentsSlugs.ADVANTAGES]: advantages,
    [componentsSlugs.STORE]: store,
  } = useSelector(homeComponentsSelector)

  useEffect(() => {
    getPodcast().then(({ data: result }) => {
      if (result && Array.isArray(result)) {
        setData(
          result.map((res) => {
            const dataEpisode: any = res?.lnf_episodes_id.filter(
              (episode: any) => episode.id === res.feature_episode_id
            )
            const slugepisode: any = get(dataEpisode, [0, 'slug'])
            return {
              name: res.title,
              image: get(res, ['attachement_thubnail', 0, 'virtualPath']),
              link: `/podcasts/${res.slug}/episode/${slugepisode}`,
              order: res.featured_order,
            }
          })
        )
      }
    })
  }, [])

  const orderedData = (dataToOrder: DataToOrder[]): any => {
    return dataToOrder.sort(sortByOrderFromTFM)
  }

  const leftCard = {
    image: about?.left_image,
    name: about?.left_title,
    path: about?.left_path,
  }

  const aboutRightCards = [
    {
      image: about?.right_top_image,
      name: about?.right_top_title,
      path: about?.right_top_path,
    },
    {
      image: about?.right_bottom_image,
      name: about?.right_bottom_title,
      path: about?.right_bottom_path,
    },
  ]

  return (
    <Container>
      {Boolean(banner) && (
        <Banner
          image={banner?.background || 'https://i.imgur.com/4TNc8r2.png'}
          text={banner?.subtitle}
          title={banner?.title}
          button={{ label: banner?.link_label, path: banner?.link }}
        />
      )}
      {data?.length ? (
        <Gallery
          title="Podcasts"
          items={orderedData(data)}
          backgroundImage="https://i.imgur.com/gyXcLF8.png"
          backgroundColor="white"
        />
      ) : null}

      {Boolean(newsletter) && (
        <Newsletter
          backgroundImage={newsletter?.background}
          confirmMessage={newsletter?.confirm_message}
          titleBottom={newsletter?.bottom_title}
          titleMiddle={newsletter?.middle_title}
          titleTop={newsletter?.top_title}
          url={newsletter?.url}
        />
      )}

      {Boolean(about) && <CardCollection title={about?.title} leftCard={leftCard} rightCards={aboutRightCards} />}

      {Boolean(subscription) && (
        <Highlight
          button={subscription?.link_label}
          link={subscription?.link}
          message={subscription?.title}
          backgroundImage={subscription?.background}
          textColor="white"
        />
      )}

      {Boolean(advantages) && <Features features={advantages?.items} title={advantages?.title} />}

      {Boolean(store) && (
        <Promote
          backgroundImage={store?.background}
          message={store?.subtitle}
          title={store?.title}
          button={store?.link_label}
          link={store?.link}
          image={store?.image}
        />
      )}
    </Container>
  )
}

export default Home
