import get from 'lodash/get'
import map from 'lodash/map'
import ObjectMapper from 'object-mapper'
import { singleFile } from 'lib/propsParser/singleFile'

export const footerMapper = (original: any): any =>
  ObjectMapper.merge(original, original, {
    socials: {
      key: 'socials',
      transform: () => ({
        facebook: original.facebook,
        twitter: original.twitter,
        instagram: original.instagram,
      }),
    },
    columns: {
      key: 'columns',
      transform: (value: any) =>
        map(value, (i) => ({
          ...i,
          options: map(i.options, (item2) => ({
            path: item2['d29c6f92-6dd1-46d1-936e-7a8128314879'],
            label: item2['98b9e2ef-03ed-4c46-9fe8-03c9582c0027'],
          })),
        })),
    },
    contactLogos: {
      key: 'contactLogos',
      transform: (value: any) => map(value, (item) => singleFile(item.logo)),
    },
    footerText: {
      key: 'footerText',
      transform: (value: any) => map(value, (item) => item.text),
    },
    logo: {
      key: 'logo',
      transform: singleFile,
    },
  })

const getter = (defaults: any) => (target: any, name: any) => {
  if (name === 'socials')
    return {
      facebook: target.facebook,
      twitter: target.twitter,
      instagram: target.instagram,
    }
  if (name in target && (target[name] || target[name] === 0)) {
    switch (name) {
      case 'columns':
        return map(target.columns, (i) => ({
          ...i,
          options: map(i.options, (item2) => ({
            path: item2['d29c6f92-6dd1-46d1-936e-7a8128314879'],
            label: item2['98b9e2ef-03ed-4c46-9fe8-03c9582c0027'],
          })),
        }))
      case 'contactLogos':
        return map(target.contactLogos, (item) => {
          try {
            return get(JSON.parse(item.logo), [0, 'virtualPath'])
          } catch {
            return ''
          }
        })
      case 'footerText':
        return map(target.footerText, (item) => item.text)
      case 'logo':
        try {
          return get(JSON.parse(target.logo), [0, 'virtualPath'])
        } catch {
          return ''
        }

      default:
        return target[name]
    }
  }
  return defaults[name]
}

export const footerParser = (tfmValues: any, defaults: any = {}) => {
  return new Proxy(tfmValues, {
    get: getter(defaults || {}),
  })
}
