import React, { FC } from 'react'
import { Container, LinkButton, Title, Message } from './styled'

type Props = {
  backgroundColor?: string // Background color of the section
  backgroundImage?: string // Background image of the section
  button: string // Button Value
  buttonColor?: string // Button color
  buttonTextColor?: string // Text button color
  link: string // Link to redirect when click on button
  message: string // Message to be displayed
  noBottomSpacing?: boolean
  target?: boolean // Should open in a new tab
  textColor?: string // Color of the message
  content?: string
}

export const Highlight: FC<Props> = (props) => {
  const {
    backgroundColor,
    backgroundImage,
    button,
    buttonColor,
    buttonTextColor,
    link,
    message,
    content,
    noBottomSpacing,
    target,
    textColor,
  } = props
  return (
    <Container
      backgroundColor={backgroundColor}
      background={`url(${backgroundImage})`}
      noBottomSpacing={noBottomSpacing}
    >
      <Title color={textColor}>{message}</Title>
      {Boolean(content) && <Message>{content}</Message>}
      {Boolean(link) && (
        <LinkButton
          to={link}
          backgroundColor={buttonColor}
          color={buttonTextColor}
          target={target ? 'blank' : undefined}
        >
          {button}
        </LinkButton>
      )}
    </Container>
  )
}
