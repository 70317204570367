import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const ContactContainer = styled.div`
  grid-area: contact;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const ContactDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const ContactLogo = styled.img`
  max-width: 94px;
  max-height: 31px;
`

export const ContactLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 450px) {
    position: absolute;
    left: 160px;
    bottom: 0;
  }
`

export const ContactSocial = styled.a`
  margin-right: 15px;
  color: inherit;
  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
`

export const ContactSocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;
  @media screen and (max-width: 450px) {
    margin-top: 20px;
    width: 50%;
  }
`

type TextProps = {
  $last?: boolean
}

export const ContactText = styled.p<TextProps>`
  font-size: 16px;
  line-height: 20px;
  opacity: 0.75;
  margin: 6px 0;
  @media screen and (max-width: 450px) {
    margin: 3px 0;
    &::after {
      content: '-';
      margin: ${({ $last }) => ($last ? '0' : '0 16px')};
      display: ${({ $last }) => ($last ? 'none' : 'inline')};
    }
  }
`

export const Container = styled.div<ColorProps>`
  width: 100%;
  background-color: #0a142f;
  color: #ffffff;
  display: flex;
  ${color};
  @media screen and (max-width: 2560px) and (max-width: 1024px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 769px) and (min-width: 741px) {
    margin-top: 76px;
  }
  @media screen and (max-width: 741px) {
    margin-top: 216px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 360px) {
    margin-top: 170px;
  }
`

export const Content = styled.div`
  padding: 10% 10% 5%;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'navigation navigation contact newsletter'
    'footer footer footer footer';
  @media screen and (max-width: 1024px) {
    padding: 5% 5% 2.5%;
    width: 90%;
    grid-template-columns: repeat(3, 1fr) 1.75fr;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 5% 16px 2.5%;
    grid-template-columns: 1fr 0.5fr;
    grid-row-gap: 20px;
    grid-template-areas:
      'navigation contact'
      'newsletter newsletter'
      'footer footer';
  }
  @media screen and (max-width: 450px) {
    width: calc(100% - 32px);
    padding: 2.5% 16px 2.5%;
    display: flex;
    flex-direction: column;
  }
`

export const FooterContent = styled.div`
  width: 480px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 110px;
  }
`

export const FooterLogo = styled.img`
  max-width: 85px;
  max-height: 55px;
  position: absolute;
  left: 0;
`
type FooterRowProps = {
  borderColor?: string
}

export const FooterRow = styled.div<FooterRowProps>`
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ borderColor }) => borderColor || 'white'};
  margin-top: 20px;
  padding: 40px 0;
  position: relative;
`

export const FooterText = styled.p<ColorProps>`
  font-size: 14px;
  margin: 0;
  line-height: 17px;
  color: inherit;
  @media screen and (max-width: 450px) {
    padding: 2px 0;
  }
`

export const NavigationContainer = styled.div`
  grid-area: navigation;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
`

export const NavigationColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  @media screen and (max-width: 1024px) {
    margin-right: 5px;
    max-width: 200px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 5px;
    max-width: 300px;
  }
  @media screen and (max-width: 450px) {
    margin-right: 0;
    max-width: unset;
    width: 100%;
    margin-top: 20px;
  }
`

export const NavigationLink = styled(Link)<TextProps>`
  font-size: 16px;
  line-height: 20px;
  opacity: 0.75;
  margin: 6px 0;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 450px) {
    margin: 3px 0;
    &::after {
      content: '-';
      margin: ${({ $last }) => ($last ? '0' : '0 16px')};
      display: ${({ $last }) => ($last ? 'none' : 'inline')};
    }
  }
`

export const NavigationLinkContainer = styled.div`
  margin-top: 9px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

type NavigationTitleProps = {
  paddingBottom?: string
}

export const NavigationTitle = styled.h2<NavigationTitleProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '0'};
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`

export const NewsletterContainer = styled.div`
  grid-area: newsletter;
`
