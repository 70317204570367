import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'

export const Button = styled(Link)<ColorProps>`
  border: none;
  background: #eef2f9;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 0 4rem;
  color: #131313;
  align-self: center;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  ${color};
`

export const Container = styled.div<ColorProps>`
  padding: 20px 10% 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  ${color};
  @media screen and (max-width: 1024px) {
    width: 90%;
    padding: 20px 5% 40px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 40px;
`

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  margin-top: 2rem;
  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding: 10px 1px;
  }
`

export const Title = styled.h1`
  color: inherit;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin: 0;
  @media screen and (max-width: 450px) {
    font-size: 26px;
    line-height: 31px;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: 10px;
  color: inherit;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    line-height: 20px;
  }
`
