import axios from 'axios'
import get from 'lodash/get'
import intersection from 'lodash/intersection'

const query = (slug: any) => `
query {
  rateTypes: lnfTypeRates {
    id
    name
    order: position_order
    show_survey
    title
    subtitle
    question
    lnf_type_rate_values_id {
      id
      position_order
      description
    }
  }
  lnfPodcasts(where: { slug: "${slug}", state: "Publicado" }) {
    id
    slug
    title
    description
    attachement
    cover_image
    featuredEpisodeID: feature_episode_id
    episodes: lnf_episodes_id(where: { state:"Publicado" }) {
      link: link_episode
      blog: additional_content
      id
      slug
      title
      description
      featured
      order: position_order
      rols: lnf_episode_acces_subsc_rols_id {
        id
        rol: subsc_role_id {
          id
          name
        }
      }
      finance: lnf_finances_id {
        id
        value
        color
        lnf_color_financing_id {
          id
          color: color_hex
        }
        type: lnf_finances_type_id {
          name
        }
      }
      gratitudes
      credits: lnf_episode_credits {
        id
        name
        type: type_credit
      }
      attachement
      featureImage: attachement_featured
      thumbnail: attachement_thubnail
      duration
    }
  }
}
`
// TODO: link: link_episode IS BREAKING GRAPHQL

export const details = async (slugPodcast: any, slugEpisode: any, rols: any[]): Promise<any> => {
  try {
    const {
      data: {
        data: {
          rateTypes,
          lnfPodcasts: [data],
        },
      },
    } = await axios.post(`${process.env.REACT_APP_TFM_API}/graphql`, { query: query(slugPodcast) })
    const { episodes: allEpisodes, ...podcast } = data

    const episodes = allEpisodes.filter((e: any) => {
      if (
        process.env.REACT_APP_PUBLIC_ROL_ID &&
        e.rols?.find((r: any) => r?.rol?.id === process.env.REACT_APP_PUBLIC_ROL_ID)
      ) {
        return true
      }

      if (!e.rols?.length) return true

      return !!intersection(
        e.rols?.map((r: any) => r?.rol?.id),
        rols
      ).length
    })

    const featured = episodes?.find((e: any) => `${e.slug}` === `${slugEpisode}`)

    if (!featured) throw new Error()
    rateTypes.sort((a: any, b: any) => a.order - b.order)

    episodes.sort((a: any, b: any) => a.order - b.order)
    const payload = {
      rateTypes: rateTypes?.map((item: any) => ({
        label: item.name,
        value: item.name,
        id: item.id,
        question: item.question,
        title: item.title,
        subtitle: item.subtitle,
        feedback: item.show_survey,
        options: item.lnf_type_rate_values_id,
      })),
      featured: {
        ...featured,
        gratitudes: featured?.gratitudes,
        featureImage: get(featured, ['attachement', 0, 'virtualPath']),
        credits: featured?.credits?.map((item: any) => `${item.type}: ${item.name}`),
        finance: featured?.finance?.map((item: any) => ({
          value: item?.value,
          label: item?.type?.name,
          image: 'https://i.imgur.com/nSEWJ4m.png',
          color: item?.lnf_color_financing_id?.color || item?.color,
        })),
      },
      episodes: episodes?.map((e: any) => ({
        ...e,
        description: podcast.title,
        embed: e.link, // can't load link property in graphql
        thumbnail: get(e, ['thumbnail', 0, 'virtualPath']),
        image: get(e, ['thumbnail', 0, 'virtualPath']),
        featureImage: get(e, ['featureImage', 0, 'virtualPath']),
        navigationLink: `/podcasts/${podcast.slug}/episode/${e.slug}`,
      })),
      podcast,
    }

    return payload
  } catch {
    return {
      redirect: true,
      rateTypes: [] as any,
      featured: {} as any,
      episodes: [] as any,
      podcast: {} as any,
    }
  }
}
