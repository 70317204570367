import { FC, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { Newsletter } from 'views/screens/Newsletter'
import { AboutUs } from 'views/screens/AboutUs'
import { Contact } from 'views/screens/Contact'
import Home from 'views/screens/Home'
import { Login } from 'views/screens/Login'
import { Podcast } from 'views/screens/Podcast'
import { Podcasts } from 'views/screens/Podcasts'
import { Signup } from 'views/screens/Signup'
import { Exclusive } from 'views/screens/Exclusive'
import { useSelector } from 'react-redux'
import { userSelector } from 'ducks/user'
import { ExclusiveHome } from 'views/screens/ExclusiveHome'
import { NotFound } from 'views/screens/NotFound'
import { ForgotPassword } from 'views/screens/ForgotPassword'
import { getPages } from 'services/pages'
import { Buildin } from 'views/components/buildin'
import { SubscriptionManagement } from 'views/screens/SubscriptionManagement'
import { AddSubscription } from 'views/screens/AddSubscription'
import { PaymentResult } from 'views/screens/PaymentResult'

type PrivateRouteProps = {
  children: JSX.Element
  exact?: boolean
  path: string
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children, exact, path }) => {
  const userState = useSelector(userSelector)
  const token = userState.get('token')

  if (!token) {
    return <Redirect to="/login" />
  }
  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  )
}

type PageComponent = {
  type: string
  payload: any
}

type DynamicPage = {
  id: string
  slug: string
  page_component_ids: Array<{
    component: PageComponent
  }>
}

type FormatedPage = {
  id: string
  slug: string
  components: PageComponent[]
}

type DynamicRoutesProps = {
  pages: FormatedPage[]
}

const formatPages = (pages: DynamicPage[]): FormatedPage[] => {
  return pages?.map((page) => ({
    ...page,
    components: page.page_component_ids.map((pageComponent) => ({
      ...pageComponent.component,
    })),
  }))
}

const DynamicRoute: FC<DynamicRoutesProps> = ({ pages }: DynamicRoutesProps) => {
  const { slug } = useParams<{ slug: string }>() || {}

  const components = (pages || []).find((page) => page.slug === slug)?.components

  if (Array.isArray(components) && components.length) {
    return <Buildin items={components} />
  }

  return null
}

const MainRouter: FC = () => {
  const [pages, setPages] = useState<FormatedPage[]>([])

  useEffect(() => {
    getPages().then((res) => {
      const formatedPages = formatPages(res.data)
      setPages(formatedPages)
    })
  }, [])

  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/podcasts" exact>
        <Podcasts />
      </Route>
      <Route path="/podcasts/:slugpodcast/episode/:slugepisode" exact>
        <Podcast />
      </Route>
      <Route path="/quienes-somos" exact>
        <AboutUs />
      </Route>
      <Route path="/contacto" exact>
        <Contact />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/newsletter" exact>
        <Newsletter />
      </Route>
      <Route path="/sign-up" exact>
        <Signup />
      </Route>
      <Route path="/payment-result">
        <PaymentResult />
      </Route>
      <Route path="/forgot-password" exact>
        <ForgotPassword />
      </Route>
      <PrivateRoute path="/exclusive/:content" exact>
        <Exclusive />
      </PrivateRoute>
      <PrivateRoute path="/exclusive" exact>
        <ExclusiveHome />
      </PrivateRoute>
      <Route path="/pages/:slug">
        <DynamicRoute pages={pages} />
      </Route>
      <PrivateRoute path="/subscription-management" exact>
        <SubscriptionManagement />
      </PrivateRoute>

      <PrivateRoute path="/add-subscription" exact>
        <AddSubscription />
      </PrivateRoute>

      <Route component={NotFound} />
    </Switch>
  )
}

export default MainRouter
