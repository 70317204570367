import React, { FC } from 'react'
import { Button, Container, Content, Description, Image, Text, Title, SubTitle } from './styled'

type Props = {
  backgroundColor?: string // Background Color
  backgroundImage?: string // Background Image URL
  button?: string // Button Value
  buttonColor?: string // Button Color
  buttonTextColor?: string // Color of button text
  image?: string // Image URL
  link?: string // Link to Redirect
  message: string // Message to be displayed
  subtitle?: string // subtitle to be displayed
  textColor?: string // Color of all texts
  title?: string // Title to be displayed
  target?: boolean // Should open in new Window
  variant?: string // change default styles layout
}

export const Promote: FC<Props> = (props) => {
  const {
    backgroundColor,
    backgroundImage,
    button,
    buttonColor,
    buttonTextColor,
    image,
    link,
    message,
    textColor,
    title,
    target,
    subtitle,
    variant,
  } = props

  return (
    <Container backgroundColor={backgroundColor} background={`url(${backgroundImage})`}>
      <Content variant={variant}>
        <Description variant={variant}>
          <Title color={textColor}>{title}</Title>
          {Boolean(subtitle) && <SubTitle>{subtitle}</SubTitle>}
          <Text color={textColor}>{message}</Text>
          {link && (
            <Button
              to={link}
              backgroundColor={buttonColor}
              color={buttonTextColor}
              target={target ? 'blank' : undefined}
            >
              {button}
            </Button>
          )}
        </Description>
        {image && <Image variant={variant} src={image} />}
      </Content>
    </Container>
  )
}
