import { ServiceResponse } from '@types'
import axios from 'axios'
import Swal from 'sweetalert2'

export const resetPassword = async (email: string): Promise<ServiceResponse> => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_TFM_API}/auth/forgot-password`, { email, source: 'website' })
    if (resp.status === 200) {
      return { ok: true }
    }
    return { ok: false, message: 'Hubo un error' }
  } catch (error) {
    Swal.fire({
      title:
        'Si estás registrado con este correo, te llegará un mensaje a tu bandeja de entrada para restablecer tu contraseña.',
      icon: 'info',
      allowEnterKey: false,
      showCloseButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#000000',
      color: '#0a142f',
      iconColor: '#fcd83a',
    })
    return { ok: false, message: error.message }
  }
}
